<template>
    <div class="ol-dashboard__order">  
        <LoadingImg v-if="loading" />

        <div style="padding: 0 15px; ">
            <h4 class="ol-dashboard__container-title" style="margin-bottom:20px;">{{ products.length == 0 ? 'Terimakasih telah memberikan ulasan anda.': 'Beri Penilaian untuk Produk berikut'}}
                <router-link style="float:right;font-size: 13px;" :to="'/profile/order/'+order.ordersn" class="ol-btn ol-btn--blue">Detail Pesanan </router-link>
            </h4>
            <Alert v-if="alertMessage" type="success" message="Terimakasih telah memberikan ulasan."/>
        </div>
        <div class="formWrap" v-for="(item,i) in products" :key="item.id" >
            <div class="review-card" :style="(i == (products.length-1)) ? 'padding-bottom:80px':'border-bottom:1px solid lightgrey; padding-bottom:80px'">              
                <div>
                    <div class=" produk_review">
                        <img v-if="item.r_uni_product_variant.r_uni_product_image" :src="item.r_uni_product_variant.r_uni_product_image.image_url" :alt="item.r_uni_product_variant.name_long" :title="item.r_uni_product_variant.name_long" />
                        <img v-else :src="noImage()" :alt="item.r_uni_product_variant.name_long" :title="item.r_uni_product_variant.name_long" />
                        <div class="produk_review_desc">
                            <p>{{item.r_uni_product_variant.r_uni_product.name}}</p>
                            <small v-if="item.r_uni_product_variant.variant">Varian: <span>{{ item.r_uni_product_variant.variant }}</span></small>
                            <small v-else>Warna: <span>{{  item.r_uni_product_variant.color }}</span></small>
                        </div>
                    </div>
                </div>
                <div class="ratecontainer">
                    <div class="rate">
                        <input v-model="reviews[i].rating" type="radio" :id="'star5-'+item.id" :name="'rate-'+item.id" value="5" />
                        <label :for="'star5-'+item.id" title="text"></label>
                        <input v-model="reviews[i].rating" type="radio" :id="'star4-'+item.id" :name="'rate-'+item.id" value="4" />
                        <label :for="'star4-'+item.id" title="text"></label>
                        <input v-model="reviews[i].rating" type="radio" :id="'star3-'+item.id" :name="'rate-'+item.id" value="3" />
                        <label :for="'star3-'+item.id" title="text"></label>
                        <input v-model="reviews[i].rating" type="radio" :id="'star2-'+item.id" :name="'rate-'+item.id" value="2" />
                        <label :for="'star2-'+item.id" title="text"></label>
                        <input v-model="reviews[i].rating" type="radio" :id="'star1-'+item.id" :name="'rate-'+item.id" value="1" />
                        <label :for="'star1-'+item.id" title="text"></label>
                    </div>
                </div>
                
                <div>
                    <div class="reviewAutoContainer row">
                        <span v-for="template in reviewTemplates" :key="'template-'+template.id">
                            <a v-if="reviews[i].description.search(template.text) >= 0" class="reviewAutoItem reviewAutoItemActive" :data-index="i">{{template.text}}</a>
                            <a v-else class="reviewAutoItem" :data-index="i">{{template.text}}</a>
                        </span>
                    </div>
                </div>
                <div class="inputReview">
                    <form>
                        <div class="form-group">
                            <textarea v-model="reviews[i].description" class="form-control textarea-desc" placeholder="Berikan pendapatmu mengenai produk ini" rows="3"></textarea>
                        </div>
                    </form>
                </div>
                <div>
                    <div class="uploadReview">
                        <form class="dropzone" @submit.prevent="">
                            <input type="hidden" name="more_product_reviews_id" v-model="reviews[i].id">
                            <Vue2Dropzone ref="myVueDropzone" :id="'dz-'+i" :options="dropzoneOptions" :duplicateCheck="true" @vdropzone-removed-file="onRemovedFile" @vdropzone-sending-multiple="onSendingMultipleFile" @vdropzone-success-multiple="onSuccessMultipleFile"></Vue2Dropzone>
                        </form>
                    </div>
                </div>
                <div>
                    <a class="ol-btn btn-review" @click="onReview(reviews[i],i)"> Nilai</a>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// require('../../assets/js/dropzone/dropzone.js')
import { mapGetters, mapActions } from 'vuex'
import globalUrl from '@/util/globallUrl'
const URL = globalUrl.WS_PROFILE.PRODUCT;
export default {
    name:"ProfileOrderReview",
    components:{
        Vue2Dropzone,
    },
    data(){
        return {
            order: {},
            loading:false,
            products:[],
            reviews:[],
            dropzoneOptions: {},
            lastReview:{},
            alertMessage:false,
        }
    },
    computed:{
        ...mapGetters("profile/product",["reviewTemplates"]),
        ...mapGetters('auth', ['accessToken']),
    },
    methods:{
        ...mapActions("profile/product",["fetchReviewTemplates","saveReview","deleteReviewImage"]),
        async onReview(data,index){
            // console.log(index);
            if(!data.rating || !data.description) {
                this.$toast.open({ message: "Rating & Deksripsi wajib dilengkapi", type: "warning", duration: 5000, });
                return false;
            }
            this.alertMessage = false;
            let queuedFiles = this.$refs.myVueDropzone[index].getQueuedFiles();
            this.loading = true;
            let result = await this.saveReview(data);
            if(result.success){
                this.lastReview = result.data;

                if(queuedFiles.length > 0){
                    this.$refs.myVueDropzone[index].processQueue();
                }else{
                    this.syncDetailOrder();
                    this.loading = false;
                    this.alertMessage = true;
                    this.jqScroll2top();
                }
            }else{
                this.loading = false;
            }
        },
        syncDetailOrder(){
            let unreviews = [];
            if(this.lastReview) {
                unreviews = _.filter(this.reviews,(q) => { 
                    if(parseInt(q.more_transaction_order_products_id) != parseInt(this.lastReview.more_transaction_order_products_id)){
                        return q;
                    } 
                });
                this.reviews = unreviews;   
            }

            let detail = JSON.parse(localStorage.getItem("detailOrder"));
            let sampleProducts = detail.order.r_more_transaction_order_details[0].r_more_transaction_order_products;
            sampleProducts = _.map(sampleProducts,(q) => {
                if(this.lastReview && parseInt(this.lastReview.more_transaction_order_products_id) == parseInt(q.id)) q.r_more_product_review = this.lastReview;
                return q;
            });
            
            this.products = _.filter(sampleProducts,(q) => {
                if(!q.r_more_product_review) return q;
            });

            detail.order.r_more_transaction_order_details[0].r_more_transaction_order_products = sampleProducts;
            localStorage.setItem("detailOrder", JSON.stringify(detail));
        },
        trimBoth(textString,charlist){
            if (charlist === undefined)
            charlist = "\s";
            return (textString.replace(new RegExp("^[" + charlist + "]+"), "")).trim();
        },
        onSendingMultipleFile(file, xhr, formData) {
            // console.log("onSendingMultipleFile",this.lastReview);
            formData.append('more_product_reviews_id', this.lastReview.id);
        },
        onSuccessMultipleFile(file, response){
            this.syncDetailOrder();
            this.loading = false;
            this.alertMessage = true;
            this.jqScroll2top();
        },
        jqScroll2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        async onRemovedFile(file, xhr, formData){
            // console.log(file);
            // if(file.review_image_id) {
                // let result = await this.deleteReviewImage(file.review_image_id);
                // if(result.success){
                //     let index = _.findIndex(this.reviews,{"id":parseInt(result.data[0].more_product_reviews_id)});
                //     this.reviews[index].r_more_product_review_images = result.data;
                //     this.syncDetailOrder();
                // }
            // }
        },
        dzLoadFiles(){
            // this.$refs.myVueDropzone[i].removeFile();
            // console.log(this.reviews);
            // let unreviews = _.filter(this.reviews,{"id": null});
            // for (let i = 0; i < unreviews.length; i++) {
            //     unreviews[i].r_more_product_review_images.forEach(image => {
            //         let file = { review_image_id:image.id, name: image.name, type: image.mime_type, size:image.image_size };
            //         let url = image.image_url;
            //         this.$refs.myVueDropzone[i].manuallyAddFile(file, url);
            //     });
            // }
        },
        init(){
            let self = this;
            $("body").on("click",".reviewAutoItem",function () {
                let i = parseInt($(this).data("index"));
                let preDescription = self.reviews[i].description;            
                let text = $(this).text();
                if($(this).hasClass("reviewAutoItemActive")) {
                    $(this).removeClass("reviewAutoItemActive");
                    preDescription = (preDescription.replace(", "+text,"")).replace(text,"");
                }else{
                    $(this).addClass("reviewAutoItemActive");
                    preDescription = preDescription ? preDescription+", "+text : text;
                }
                self.reviews[i].description =  self.trimBoth(preDescription,",");
            });

            // this.dzLoadFiles(); 
        }
    },
    created(){
        this.fetchReviewTemplates();
        this.order = JSON.parse(localStorage.getItem("detailOrder")).order;
        this.products = this.order.r_more_transaction_order_details[0].r_more_transaction_order_products;
        this.products = _.filter(this.products,(q) => {
            if(!q.r_more_product_review) return q;
        });

        this.reviews = _.map(this.products,function(q){
                let item = {
                    id: q.r_more_product_review ? q.r_more_product_review.id : null,
                    more_transaction_order_products_id: q.id,
                    uni_product_variants_id: q.r_uni_product_variant.id,
                    uni_products_id: q.r_uni_product_variant.uni_products_id,
                    description: q.r_more_product_review ? q.r_more_product_review.description : "",
                    rating: q.r_more_product_review ? q.r_more_product_review.rating : "0",
                    r_more_product_review_images: q.r_more_product_review ? q.r_more_product_review.r_more_product_review_images : [],
                }
                return item;
        });

        this.dropzoneOptions = {
            url: URL._REVIEW_IMAGE_UPLOAD,
            autoProcessQueue: false,
            uploadMultiple:true,
            thumbnailWidth: 150,
            thumbnailHeight: 150,
            maxFilesize: 10,
            parallelUploads: 10,
            addRemoveLinks: true,
            headers: { "Authorization":'Bearer ' + this.accessToken },
            dictDefaultMessage:"<i style='color:grey;' class='fas fa-camera fa-lg'></i>",
            dictRemoveFile:"Hapus file",
        };

    },
    mounted(){
        this.init();
    },

}
</script>

<style lang="css" scoped>
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/js/dropzone/dropzone.css';
    @import '../../assets/css/ulasan.css';
</style>